import Metrics from '@/plugins/metrics'
import SButton from '@/components/ui/s-button'
import STitle from '@/components/ui/s-title'

export default {
  name: 'IdentifiedOthers',
  props: {
    code: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default: '',
    },
  },

  components: {
    SButton,
    STitle,
  },

  data() {
    return {
      contact: '',
      copied: false,
      copyText: 'copy',
    }
  },

  methods: {
    submit() {
      this.$emit('submit', this.contact)
    },

    copy() {
      this.$emit('copy')
      this.copyText = 'copied'

      Metrics.track('Report Code Copied', {
        source: this.source,
        anonymous: false,
      })
    },
  },
}
